import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../../context/AuthContext';
import config from '../../config/config';
import { FaUser, FaEnvelope, FaCreditCard, FaSignOutAlt } from 'react-icons/fa';
import styled from 'styled-components';

const ProfileContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ProfileCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const ProfileHeader = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const ProfileInfo = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const UserProfile = () => {
  const { apiStagingUrl, stripeCustomerPortalUrl } = config;
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${apiStagingUrl}/usermanagement/profile/`, {
          headers: { Authorization: `Token ${localStorage.getItem('token')}` },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };
    fetchUserProfile();
  }, [navigate, apiStagingUrl]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ProfileContainer>
      <Helmet>
        <title>User Profile | Market Catalysts</title>
        <meta name="description" content="View and manage your Market Catalysts user profile and subscription." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <ProfileCard>
        <ProfileHeader><FaUser /> User Profile</ProfileHeader>
        <ProfileInfo><FaUser /> Username: {user.username}</ProfileInfo>
        <ProfileInfo><FaEnvelope /> Email: {user.email}</ProfileInfo>
        {/* Add more user details here as needed */}
      </ProfileCard>

      <ProfileCard>
        <ProfileHeader><FaCreditCard /> Subscription Management</ProfileHeader>
        <ProfileInfo>
          To manage your subscription, please visit the{' '}
          <a href={stripeCustomerPortalUrl} target="_blank" rel="noopener noreferrer" style={{color: '#007bff', textDecoration: 'underline'}}>
            Subscription Management Portal
          </a>.
        </ProfileInfo>
      </ProfileCard>

      <Button onClick={handleLogout}><FaSignOutAlt /> Logout</Button>
    </ProfileContainer>
  );
};

export default UserProfile;