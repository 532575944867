const config = {
    //apiStagingUrl: 'https://marketcatalystsstaging2.azurewebsites.net',
    //tripePublishableKey: 'pk_test_51OWoegK7imM49pkJaWb7HQwErr8WKynzV07lgkHUQkvWabdIUdnVJwflATc5QlTJJxZdEgx6irxlomQCZVlNAswE00Sc47syj5',
    //stripePricingTableId: 'prctbl_1OxCwaK7imM49pkJ4NdTSbPW',
    //stripeCustomerPortalUrl: 'https://billing.stripe.com/p/login/test_cN2bLD9FQ0iQ7Xq288',
    apiStagingUrl: 'https://marketcatalystsproduction.azurewebsites.net',
    stripePublishableKey: 'pk_live_51OWoegK7imM49pkJ235wWAoX5EHcMtj5e4wKD57VmaybgNLZDjufPGmleXpvZ98C2lDVDOG1VcDdGTevgOREsSoB00n0ETuNwz',
    stripePricingTableId: 'prctbl_1Ox3enK7imM49pkJVOj9Ddeu',
    stripeCustomerPortalUrl: 'https://billing.stripe.com/p/login/aEU16H1Wf0cB1ZS5kk',

    // Other environment-specific values
  };
  
  export default config;  