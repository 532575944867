import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PricingTableView = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/register');
  };

  const pricingPlans = [
    { name: 'Monthly', price: '$20', duration: 'per month', features: ['Market Catalyst Calendar', 'Stock Portfolios', 'Earning Calendars', 'FDA Calendar'] },
    { name: '6 Months', price: '$110', duration: 'every 6 months', features: ['Market Catalyst Calendar', 'Stock Portfolios', 'Earning Calendars', 'FDA Calendar'] },
    { name: 'Yearly', price: '$199', duration: 'per year', features: ['Market Catalyst Calendar', 'Stock Portfolios', 'Earning Calendars', 'FDA Calendar'] },
  ];

  const faqItems = [
    { question: 'Is there an annual option?', answer: 'Yes, you can select the annual option during the checkout. It is $199 per year, equivalent to two months free.' },
    { question: 'How to sign up', answer: 'Click "Get Started" or "Subscribe" above and enter your details. Then you will get access right away.' },
    { question: 'How to get support?', answer: 'You can send an email directly to kris@marketcatalysts.com.' },
    { question: 'Is there a trial?', answer: 'Currently we cannot offer a trial but we do have a refund policy.' },
    { question: 'Can I cancel at any time?', answer: 'Of course. There is a "Manage subscription" button in the my account area that you get access to after signing up. This will direct you to Stripe for cancellation. You will continue to have access through the current billing cycle. You can also send us a message and we will cancel for you.' },
    { question: 'What is your refund policy?', answer: 'We offer a 7 day money back guarantee, no questions asked. Just send an email to kris@marketcatalysts.com and you will get a full refund.' },
  ];

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Pricing Plans - Market Catalysts",
    "url": "https://www.marketcatalysts.com/pricing",
    "description": "Choose a subscription plan that fits your needs and gain access to premium features like Market Catalyst Calendar, Stock Portfolios, Earning Calendars, and FDA Calendar.",
    "publisher": {
      "@type": "Organization",
      "name": "Market Catalysts",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.marketcatalysts.com/mc.png"
      }
    },
    "potentialAction": [
      {
        "@type": "SubscribeAction",
        "name": "Subscribe",
        "target": "https://www.marketcatalysts.com/subscribe"
      },
      {
        "@type": "ContactAction",
        "name": "Contact Support",
        "target": "https://www.marketcatalysts.com/support"
      }
    ],
    "keywords": "subscription plans, market analysis tools, investment subscriptions, premium market features, market catalyst calendar",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.marketcatalysts.com/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Pricing Plans",
          "item": "https://www.marketcatalysts.com/pricing"
        }
      ]
    },
    "inLanguage": "en-US",
    "mainEntity": {
      "@type": "FAQPage",
      "mainEntity": faqItems.map(item => ({
        "@type": "Question",
        "name": item.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": item.answer
        }
      }))
    }
  };

  return (
    <>
      <Helmet>
        <title>Pricing Plans - Market Catalysts</title>
        <meta name="description" content="Choose a subscription plan that fits your needs and gain access to premium features like Market Catalyst Calendar, Stock Portfolios, Earning Calendars, and FDA Calendar." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/mc.png" />
        <link rel="canonical" href="https://www.marketcatalysts.com/pricing" />
        <meta name="keywords" content="pricing, subscription plans, market catalysts, stock market insights, earning calendars, FDA calendar" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.marketcatalysts.com/pricing" />
        <meta property="og:title" content="Pricing Plans - Market Catalysts" />
        <meta property="og:description" content="Choose a subscription plan that fits your needs and gain access to premium features like Market Catalyst Calendar, Stock Portfolios, Earning Calendars, and FDA Calendar." />
        <meta property="og:image" content="https://www.marketcatalysts.com/pricing-og.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.marketcatalysts.com/pricing" />
        <meta property="twitter:title" content="Pricing Plans - Market Catalysts" />
        <meta property="twitter:description" content="Choose a subscription plan that fits your needs and gain access to premium features like Market Catalyst Calendar, Stock Portfolios, Earning Calendars, and FDA Calendar." />
        <meta property="twitter:image" content="https://www.marketcatalysts.com/pricing-twitter.jpg" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center my-8"
        >
          <h1 className="text-4xl font-bold mb-4">Market Catalysts</h1>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">At Market Catalysts, we provide you with comprehensive market insights, stock portfolios, and essential calendars to help you make informed trading decisions. Choose the plan that best fits your needs and join our community of savvy investors.</p>
        </motion.div>
        <motion.h2 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-2xl font-bold mb-4 text-center"
        >
          Choose a Subscription Plan
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {pricingPlans.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="bg-white rounded-lg shadow-md p-6"
            >
              <h3 className="text-xl font-bold mb-4">{plan.name}</h3>
              <div className="text-4xl font-bold mb-4">{plan.price}</div>
              <div className="text-gray-500 mb-4">{plan.duration}</div>
              <ul className="mb-6">
                {plan.features.map((feature) => (
                  <li key={feature} className="mb-2">
                    {feature}
                  </li>
                ))}
              </ul>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleGetStarted}
              >
                Subscribe
              </button>
            </motion.div>
          ))}
        </div>
        <motion.h3
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="text-xl font-bold mt-12 mb-4 text-center"
        >
          Common Questions
        </motion.h3>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
          {faqItems.map((item) => (
            <div key={item.question} className="mb-4">
              <h4 className="font-bold mb-2">{item.question}</h4>
              <p>{item.answer}</p>
            </div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default PricingTableView;

