import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Helmet } from 'react-helmet';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await login(username, password);
      navigate('/profile');
    } catch (err) {
      if (err.response) {
        console.error('Login error response:', err.response);
      }
      const errorMessage = err.response && err.response.data ? err.response.data.detail : 'Login failed. Please try again.';
      setError(errorMessage);
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Login - Market Catalysts",
    "url": "https://www.marketcatalysts.com/login",
    "description": "Login to Market Catalysts to access your personalized stock market analysis and insights.",
    "publisher": {
      "@type": "Organization",
      "name": "Market Catalysts",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.marketcatalysts.com/mc.png"
      }
    }
  };
  

  return (
    <>
      <Helmet>
        <title>Login - Market Catalysts</title>
        <meta name="description" content="Login to Market Catalysts to access your personalized stock market analysis and insights." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/mc.png" />
        <link rel="canonical" href="https://www.marketcatalysts.com/login" />
        <meta name="keywords" content="login, market catalysts, stock market, personalized insights" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.marketcatalysts.com/login" />
        <meta property="og:title" content="Login - Market Catalysts" />
        <meta property="og:description" content="Login to Market Catalysts to access your personalized stock market analysis and insights." />
        <meta property="og:image" content="https://www.marketcatalysts.com/login-og.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.marketcatalysts.com/login" />
        <meta property="twitter:title" content="Login - Market Catalysts" />
        <meta property="twitter:description" content="Login to Market Catalysts to access your personalized stock market analysis and insights." />
        <meta property="twitter:image" content="https://www.marketcatalysts.com/login-twitter.jpg" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="container mx-auto px-4">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto">
          {error && <p className="text-red-500 text-xs italic">{error}</p>}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username
            </label>
            <input
              id="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-center justify-between mt-6">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Log In
            </button>
            <Link to="/forgot-password" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
              Forgot Password?
            </Link>
          </div>
          <div className="text-center mt-4">
            Not a member yet?{' '}
            <Link to="/register" className="text-blue-500 hover:text-blue-800">
              Sign up now
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginForm;




