// src/App.js
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { logPageView } from './analytics';
import PrivateRoute from './components/UserManagement/PrivateRoute';
import NotFound from './components/NotFound'; // Assuming you have this component

// PrimeReact style imports
import 'primereact/resources/themes/saga-blue/theme.css'; // theme
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // icons

// Other styles
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Importing eagerly loaded components
import Header from './components/Header';
import SecondaryHeader from './components/SecondaryHeader';
import Footer from './components/Footer';
import CustomTickerTape from './components/CustomTickerTape';
import HomePage from './Pages/HomePage';
import TradingViewHomePage from './Pages/TradingViewHomePage';
import LoginForm from './components/UserManagement/LoginForm';
import RegistrationForm from './components/UserManagement/RegistrationForm';
import PricingTableView from './components/UserManagement/PricingTableView';
import ForgotPassword from './components/UserManagement/ForgotPassword';
import ResetPassword from './components/UserManagement/ResetPassword';
import ThankYouPage from './components/UserManagement/ThankYouPage';
import Restricted from './components/UserManagement/Restricted';
import StripePayment from './components/Stripe/StripePayment';
import CartPage from './components/Stripe/CartPage';
import SubscriptionPage from './components/UserManagement/SubscriptionPage';
import UserProfile from './components/UserManagement/UserProfile';

import StockSite from './components/./StockSite';

// Importing lazily loaded components
const EventAnalysisPage = lazy(() => import('./Pages/EventAnalysisPage'));
const TradingCalendarPage = lazy(() => import('./Pages/TradingCalendarPage'));
const StrapiDataPage = lazy(() => import('./Pages/StrapiDataPage'));
const CatalystCalendarPage = lazy(() => import('./Pages/CatalystCalendarPage'));
const CompanyPage = lazy(() => import('./Pages/CompanyPage'));
const TradingViewCompany = lazy(() => import('./Pages/TradingViewCompany'));
const MC110Portfolio = lazy(() => import('./Pages/PortfolioPages/MC110Portfolio'));
const PortfoliosHome = lazy(() => import('./Pages/PortfolioPages/PortfoliosHome'));
const CommunicationServices = lazy(() => import('./Pages/PortfolioPages/CommunicationServices'));
const ConsumerDiscretionary = lazy(() => import('./Pages/PortfolioPages/ConsumerDiscretionary'));
const ConsumerStaples = lazy(() => import('./Pages/PortfolioPages/ConsumerStaples'));
const Energy = lazy(() => import('./Pages/PortfolioPages/Energy'));
const Financials = lazy(() => import('./Pages/PortfolioPages/Financials'));
const BioPharmaHealthcare = lazy(() => import('./Pages/PortfolioPages/BioPharmaHealthcare'));
const Industrials = lazy(() => import('./Pages/PortfolioPages/Industrials'));
const Technology = lazy(() => import('./Pages/PortfolioPages/Technology'));
const Materials = lazy(() => import('./Pages/PortfolioPages/Materials'));
const RealEstate = lazy(() => import('./Pages/PortfolioPages/RealEstate'));
const Utilities = lazy(() => import('./Pages/PortfolioPages/Utilities'));
const AboutPage = lazy(() => import('./components/StrapiCloud/AboutPage'));
const AboutUsPage = lazy(() => import('./components/StrapiCloud/AboutUsPage'));
const ContactPage = lazy(() => import('./components/StrapiCloud/ContactPage'));
const DataDisclaimerPage = lazy(() => import('./components/StrapiCloud/DataDisclaimerPage'));
const PrivacyPolicyPage = lazy(() => import('./components/StrapiCloud/PrivacyPolicyPage'));
const TeamPage = lazy(() => import('./components/StrapiCloud/TeamPage'));
const TermsOfUsePage = lazy(() => import('./components/StrapiCloud/TermsOfUsePage'));
const StrapiHomePage = lazy(() => import('./components/StrapiCloud/StrapiHomePage'));
const ArticlesHomePage = lazy(() => import('./components/StrapiCloud/ArticlesHomePage'));
const WeeklyWatchListPage = lazy(() => import('./components/StrapiCloud/WeeklyWatchListPage'));
const WeeklyWatchListIndexPage = lazy(() => import('./components/StrapiCloud/WeeklyWatchListIndexPage'));
const MarketCatalystsCalendarPage = lazy(() => import('./Pages/CalendarPages/MarketCatalystsCalendarPage'));
const MarketCatalystsCalendarTablePage = lazy(() => import('./Pages/CalendarPages/MarketCatalystsCalendarTablePage'));
const MarketCatalystsMosaicPage = lazy(() => import('./Pages/CalendarPages/MarketCatalystsMosaicPage'));
const IPOCalendarPage = lazy(() => import('./Pages/CalendarPages/IPOCalendarPage'));
const IPOCalendarTablePage = lazy(() => import('./Pages/CalendarPages/IPOCalendarTablePage'));
const IPOMosaicPage = lazy(() => import('./Pages/CalendarPages/IPOMosaicPage'));
const EconomicCalendarPage = lazy(() => import('./Pages/CalendarPages/EconomicCalendarPage'));
const EconomicCalendarTablePage = lazy(() => import('./Pages/CalendarPages/EconomicCalendarTablePage'));
const EconomicMosaicPage = lazy(() => import('./Pages/CalendarPages/EconomicMosaicPage'));
const DividendCalendarPage = lazy(() => import('./Pages/CalendarPages/DividendCalendarPage'));
const ConferenceCalendarPage = lazy(() => import('./Pages/CalendarPages/ConferenceCalendarPage'));
const ConferenceCalendarTablePage = lazy(() => import('./Pages/CalendarPages/ConferenceCalendarTablePage'));
const ConferenceMosaicPage = lazy(() => import('./Pages/CalendarPages/ConferenceMosaicPage'));
const DividendCalendarTablePage = lazy(() => import('./Pages/CalendarPages/DividendCalendarTablePage'));
const DividendMosaicPage = lazy(() => import('./Pages/CalendarPages/DividendMosaicPage'));
const EarningsCalendarPage = lazy(() => import('./Pages/CalendarPages/EarningsCalendarPage'));
const EarningsCalendarTablePage = lazy(() => import('./Pages/CalendarPages/EarningsCalendarTablePage'));
const EarningsMosaicPage = lazy(() => import('./Pages/CalendarPages/EarningsMosaicPage'));
const SplitsCalendarPage = lazy(() => import('./Pages/CalendarPages/SplitsCalendarPage'));
const SplitsMosaicPage = lazy(() => import('./Pages/CalendarPages/SplitsMosaicPage'));
const ConferenceCallCalendarTablePage = lazy(() => import('./Pages/CalendarPages/ConferenceCallCalendarTablePage'));
const ConferenceCallMosaicPage = lazy(() => import('./Pages/CalendarPages/ConferenceCallMosaicPage'));
const FDACalendarPage = lazy(() => import('./Pages/CalendarPages/FDACalendarPage'));
const FDACalendarTablePage = lazy(() => import('./Pages/CalendarPages/FDACalendarTablePage'));
const FDAMosaicPage = lazy(() => import('./Pages/CalendarPages/FDAMosaicPage'));
const CatalystsCalendarsHome = lazy(() => import('./Pages/CalendarPages/CatalystsCalendarsHome'));
const ConferenceCallCalendarPage = lazy(() => import('./Pages/CalendarPages/ConferenceCallCalendarPage'));
const ConferenceCalendarViewPage = lazy(() => import('./Pages/CalendarPages/ConferenceCalendarViewPage'));
const MarketOverviewPage = lazy(() => import('./Pages/StockPages/MarketOverviewPage'));
const MarketDataPage = lazy(() => import('./Pages/StockPages/MarketDataPage'));
const StockMarketPage = lazy(() => import('./Pages/StockPages/StockMarketPage'));
const StockHeatmapPage = lazy(() => import('./Pages/StockPages/StockHeatmapPage'));
const ScreenerPage = lazy(() => import('./Pages/StockPages/ScreenerPage'));
const CryptoHeatmapPage = lazy(() => import('./Pages/StockPages/CryptoHeatmapPage'));
const CryptoMarketPage = lazy(() => import('./Pages/StockPages/CryptoMarketPage'));
const ForexCrossRatePage = lazy(() => import('./Pages/StockPages/ForexCrossRatePage'));
const ForexHeatmapPage = lazy(() => import('./Pages/StockPages/ForexHeatmapPage'));
// Import Catalyst Guides
const ResourcesHomePage = lazy(() => import('./Pages/CatalystsGuide/ResourcesHomePage'));
const CatalystsGuideHomePage = lazy(() => import('./Pages/CatalystsGuide/CatalystsGuideHomePage'));
const MarketCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/MarketCatalystsGuide'));
const StockCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/StockCatalystsGuide'));
const CryptoCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/CryptoCatalystsGuide'));
const EarningsCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/EarningsCatalystsGuide'));
const FDACalendarGuide = lazy(() => import('./Pages/CatalystsGuide/FDACalendarGuide')); // Updated File Name
const GeopoliticalCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/GeopoliticalCatalystsGuide'));
const WorldEventCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/WorldEventCatalystsGuide'));
const IPOCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/IPOCatalystsGuide'));
const DividendCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/DividendCatalystsGuide'));
const StockSplitCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/StockSplitCatalystsGuide'));
const ConferenceCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/ConferenceCatalystsGuide'));
const EconomicCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/EconomicCatalystsGuide'));
const TradingCatalystsGuide = lazy(() => import('./Pages/CatalystsGuide/TradingCatalystsGuide'));
const OptionsStrategiesGuide = lazy(() => import('./Pages/CatalystsGuide/OptionsStrategiesGuide'));

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {/* Eagerly loaded routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<StrapiHomePage />} />
        <Route path="/trading" element={<TradingViewHomePage />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/pricing" element={<PricingTableView />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
        <Route path="/restricted" element={<Restricted />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/subscribe" element={<SubscriptionPage />} />
        <Route path="/payment" element={<StripePayment />} />
        <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
        <Route path="/success" element={<h1>Payment Successful!</h1>} />
        <Route path="/stock-site" element={<StockSite />} />

        {/* Lazily loaded routes */}
        <Route path="/analysis" element={<PrivateRoute><EventAnalysisPage /></PrivateRoute>} />
        <Route path="/calendar" element={<TradingCalendarPage />} />
        <Route path="/strapi-data" element={<StrapiDataPage />} />
        <Route path="/stock-market-catalyst-calendars-home" element={<CatalystsCalendarsHome />} />
        <Route path="/catalyst-calendar" element={<PrivateRoute><CatalystCalendarPage /></PrivateRoute>} />
        <Route path="/marketcatalysts-calendar-old" element={<MarketCatalystsCalendarPage />} />
        <Route path="/marketcatalysts-calendar" element={<MarketCatalystsCalendarTablePage />} />
        <Route path="/marketcatalysts-calendar-mosaic" element={<MarketCatalystsMosaicPage />} />
        <Route path="/earnings-calendar-old" element={<PrivateRoute><EarningsCalendarPage /></PrivateRoute>} />
        <Route path="/earnings-calendar" element={<EarningsCalendarTablePage />} />
        <Route path="/earnings-calendar-mosaic" element={<EarningsMosaicPage />} />
        <Route path="/fda-calendar-old" element={<PrivateRoute><FDACalendarPage /></PrivateRoute>} />
        <Route path="/fda-calendar" element={<FDACalendarTablePage />} />
        <Route path="/fda-calendar-mosaic" element={<FDAMosaicPage />} />
        <Route path="/conferencecall-calendar-old" element={<PrivateRoute><ConferenceCallCalendarPage /></PrivateRoute>} />
        <Route path="/conferencecall-calendar" element={<ConferenceCallCalendarTablePage />} />
        <Route path="/conferencecall-calendar-mosaic" element={<ConferenceCallMosaicPage />} />
        <Route path="/economic-calendar-old" element={<PrivateRoute><EconomicCalendarPage /></PrivateRoute>} />
        <Route path="/economic-calendar" element={<EconomicCalendarTablePage />} />
        <Route path="/economic-calendar-mosaic" element={<EconomicMosaicPage />} />
        <Route path="/conference-calendar-old" element={<PrivateRoute><ConferenceCalendarPage /></PrivateRoute>} />
        <Route path="/conference-calendar" element={<ConferenceCalendarTablePage />} />
        <Route path="/conference-calendar-mosaic" element={<ConferenceMosaicPage />} />
        <Route path="/conference-calendar-view" element={<ConferenceCalendarViewPage />} />
        <Route path="/ipo-calendar-old" element={<PrivateRoute><IPOCalendarPage /></PrivateRoute>} />
        <Route path="/ipo-calendar" element={<IPOCalendarTablePage />} />
        <Route path="/ipo-calendar-mosaic" element={<IPOMosaicPage />} />
        <Route path="/dividend-calendar-old" element={<PrivateRoute><DividendCalendarPage /></PrivateRoute>} />
        <Route path="/dividend-calendar" element={<DividendCalendarTablePage />} />
        <Route path="/dividend-calendar-mosaic" element={<DividendMosaicPage />} />
        <Route path="/splits-calendar" element={<SplitsCalendarPage />} />
        <Route path="/splits-calendar-mosaic" element={<SplitsMosaicPage />} />
        <Route path="/company/:ticker" element={<CompanyPage />} />
        <Route path="/market-overview" element={<MarketOverviewPage />} />
        <Route path="/market-data" element={<MarketDataPage />} />
        <Route path="/stock-market" element={<StockMarketPage />} />
        <Route path="/stock-heatmap" element={<StockHeatmapPage />} />
        <Route path="/screener" element={<ScreenerPage />} />
        <Route path="/crypto-heatmap" element={<CryptoHeatmapPage />} />
        <Route path="/crypto-market" element={<CryptoMarketPage />} />
        <Route path="/forex-cross-rate" element={<ForexCrossRatePage />} />
        <Route path="/forex-heatmap" element={<ForexHeatmapPage />} />
        <Route path="/invest/portfolios/market-catalysts-flagship-stocks" element={<MC110Portfolio />} />
        <Route path="/invest/portfolios" element={<PortfoliosHome />} />
        <Route path="/invest/portfolios/communication-services-stocks" element={<CommunicationServices />} />
        <Route path="/invest/portfolios/consumer-discretionary-stocks" element={<ConsumerDiscretionary />} />
        <Route path="/invest/portfolios/consumer-staples-stocks" element={<ConsumerStaples />} />
        <Route path="/invest/portfolios/energy-stocks" element={<Energy />} />
        <Route path="/invest/portfolios/financials-stocks" element={<Financials />} />
        <Route path="/invest/portfolios/biopharma-healthcare-stocks" element={<BioPharmaHealthcare />} />
        <Route path="/invest/portfolios/industrials-stocks" element={<Industrials />} />
        <Route path="/invest/portfolios/technology-stocks" element={<Technology />} />
        <Route path="/invest/portfolios/materials-stocks" element={<Materials />} />
        <Route path="/invest/portfolios/real-estate-stocks" element={<RealEstate />} />
        <Route path="/invest/portfolios/utilities-stocks" element={<Utilities />} />
        <Route path="/resources" element={<ResourcesHomePage />} />
        <Route path="/catalysts-guide" element={<CatalystsGuideHomePage />} />
        <Route path="/market-catalysts-guide" element={<MarketCatalystsGuide />} />
        <Route path="/stock-catalysts-guide" element={<StockCatalystsGuide />} />
        <Route path="/crypto-catalysts-guide" element={<CryptoCatalystsGuide />} />
        <Route path="/earnings-catalysts-guide" element={<EarningsCatalystsGuide />} />
        <Route path="/fda-catalysts-calendar-guide" element={<FDACalendarGuide />} /> {/* Updated Route */}
        <Route path="/geopolitical-catalysts-guide" element={<GeopoliticalCatalystsGuide />} />
        <Route path="/world-event-catalysts-guide" element={<WorldEventCatalystsGuide />} />
        <Route path="/ipo-catalysts-guide" element={<IPOCatalystsGuide />} />
        <Route path="/dividend-catalysts-guide" element={<DividendCatalystsGuide />} />
        <Route path="/stock-split-catalysts-guide" element={<StockSplitCatalystsGuide />} />
        <Route path="/conference-catalysts-guide" element={<ConferenceCatalystsGuide />} />
        <Route path="/economic-catalysts-guide" element={<EconomicCatalystsGuide />} />
        <Route path="/trading-catalysts-guide" element={<TradingCatalystsGuide />} />
        <Route path="/options-strategies-guide" element={<OptionsStrategiesGuide />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/articles" element={<ArticlesHomePage />} />
        <Route path="/weekly-watch-list" element={<WeeklyWatchListIndexPage />} />
        <Route path="/weekly-watch-list/:slug" element={<WeeklyWatchListPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/data-disclaimer" element={<DataDisclaimerPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="/tradingviewcompany" element={<TradingViewCompany />} />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header />
          <SecondaryHeader />
          <CustomTickerTape />
          <AppRoutes />
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;




